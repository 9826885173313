import React from "react";
import Header from "./Header";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png"; // Ensure this path is correct
import CsrImage from "../assets/MaskGroup.png"; // Ensure this path is correct
import Footer from "./Footer";
import { AppAvailable } from "./AppAvailable";
import CsrImages from "./CsrImages";

export default function CsrPage() {
  return (
    <>
      <Header />
      <div
        className="justify-center items-center flex flex-col"
        style={{
          backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100%",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full">
          <Navbar />
        </div>

        <div className="flex flex-row justify-center align-center m-10">
          <div className="w-1/2 mt-24 mr-48">
            <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950">
              CSR
            </span>
            <p className="text-start text-[20px] w-[500px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">
              Creating Value for People and Planet
            </p>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col item-center justify-start mt-14">
              <img
                src={CsrImage}
                alt="CSR Image"
                className="w-[345px] border-[#10C06F] border-[5px] rounded-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <div className="w-[1100px]">
          <p className="w-full max-w-[1000px] px-6 mx-4 mt-20 text-center sm:text-start text-[20px] sm:text-[30px] leading-[48px] font-barlowcondensed font-light italic text-gray-600 flex flex-col">
            Dear Sir,
          </p>
          <div className="pt-10">
            <p className="w-full max-w-[1000px] px-6 mx-4 text-center sm:text-start text-[20px] sm:text-[16px] leading-[19px] font-barlowcondensed italic text-[#666666] flex flex-col">
              On 1st March'2022, We were fortunate to represent OHM and the
              Organization’s sensitivity to a social cause at FCN - Foundation
              for Children in Need. The commendable job and energy and time
              given by Smt. Dr Geetha and Shri T Reddy Garu cannot be explained
              in words. The facility at Shaad Nagar is a loving and caring home
              to people with difficulties, different abilities and
              sensibilities. Yesterday there were many children and adults in
              attendance, suffering from Muscular Dystrophy as part of the
              donation drive. Two Senior members, themselves suffering from this
              concern were the main coordinators for the drive. Truly, all of
              them were grateful and cheerful on receiving some basic
              necessities contributed by OHM, this was possible only because of
              you all. Thank You Once Again.
            </p>
            <div className="flex items-center pt-8 pb-10">
              <p className="w-full max-w-[1000px] px-6 mx-4 text-center sm:text-start text-[20px] sm:text-[16px] leading-[19px] font-barlowcondensed italic text-[#666666] flex flex-col">
                Our sincere Thank You to Mrs. Parneetha for helping us
                coordinate this effort from beginning to end and who of course
                is a huge support to the foundation as well! As an organization,
                we can support different timelines in many ways and with
                different teams as well.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-40">
        <CsrImages />
      </div>

      <div>
        <AppAvailable />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
