import React from 'react'
import WhiteAutoCurosal from './WhiteAutoCurosal'
import Product from "../assets/productbg.png"
 const ProductContent = () => {
  return (
    <div>
      <div 
    >
        <WhiteAutoCurosal/>
       
    </div>
    

    </div>
    
  )
}
export default ProductContent
