import React from "react";
import Header from "./Header";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import OhmGroupImage from "../assets/OhmGroup.png";
import OhmGroupBgImage from "../assets/OhmGroupBgImage.png";
import OhmGroupBgImage2 from "../assets/OhmGroupBgImage2.png";
import OhmGroupAuto1 from "../assets/OhmGroupAuto1.png";
import OhmChargingCar from "../assets/OhmChargingCar.png";
import OhmMobileImage from "../assets/OhmMobileImage.png";
import OhmGroupCar1 from "../assets/OhmGroupCar1.png";
import DiveButton from "../assets/DiveButton.png";
import ReDefineButton from "../assets/ReDefineButton.png";
import ExploreButton from "../assets/ExploreButton.png";
import OhmGroupCar2 from "../assets/OhmGroupCar2.png";
import OhmGroupCar3 from "../assets/OhmGroupCar3.png";
import OhmGroupCar4 from "../assets/OhmGroupCar4.png";
import Footer from "./Footer";
import { AppAvailable } from "./AppAvailable";

export default function OhmGroup() {
  return (
    <>
      <Header />
      <div
        className="justify-center items-center flex flex-col"
        style={{
          backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100%",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full">
          <Navbar />
        </div>

        <div className="flex flex-row justify-center align-center m-10">
          <div className="w-1/2 mt-24 mr-48">
            <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950">
              OHM Group
            </span>
            <p className="text-start text-[20px] w-[500px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">
              Innovating Today, Leading Tomorrow{" "}
            </p>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col item-center justify-start mt-14">
              <img
                src={OhmGroupImage}
                alt="OHM grp Image"
                className="w-[345px] border-[#10C06F] border-[5px] rounded-full"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="w-full"
          style={{
            backgroundImage: `url(${OhmGroupBgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex justify-center items-center pt-20">
            <div className="flex-1 mr-8 sm:mr-16">
              <p className="text-left font-barlowsemicondensed text-4xl font-light text-[#197D71] pl-20">
                OHM Automotive Pvt. Ltd.
              </p>
              <p className="text-center sm:text-left pt-8 pl-20">
                OHM is a fully established Electric 2-Wheeler, 3-Wheeler, and
                4-Wheeler Manufacturing Company operating car & cargo leasing,
                tracking, and fleet management business. We are a green company
                working to get pollution down and enhance green mobility, our
                driving force is the passion to provide efficient and
                personalized customer care service to all those who engage with
                us. We always strive to exceed our customers’ expectations by
                keeping our promises and always assuring them of our trust. We
                are a startup with past experience of 10 years in Renewable
                Solar Power Projects, we have been working towards sustainable
                environment for the past 10 years. With OHM E-CARS, we are
                bringing green power to the transport and mobility along with
                our OHM E-CHARGING stations. We ventured into electric mobility
                solutions with our vision of lower carbon emissions and greener
                planet and supporting it.
              </p>
            </div>

            <div className="flex-1 ml-8 sm:ml-16">
              <img
                src={OhmGroupAuto1}
                alt="auto"
                className="w-full h-auto sm:w-[600px] sm:h-[500px] object-contain"
              />
              <img src={DiveButton} className="ml-28" />
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="">
        <div>
          <div
            className="w-full h-[500px] sm:h-[600px]"
            style={{
              backgroundImage: `url(${OhmGroupBgImage2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex justify-center items-center pt-20">
              <div className="flex flex-1 flex-col">
                <div className="flex justify-center">
                  <img
                    src={OhmGroupCar3}
                    alt="auto"
                    className="w-2/3 h-auto sm:w-[200px]"
                  />
                  <img
                    src={OhmGroupCar4}
                    alt="auto"
                    className="w-2/3 h-auto sm:w-[200px]"
                  />
                </div>
                <div className="flex justify-center ">
                  <img
                    src={OhmGroupCar1}
                    alt="auto"
                    className="w-2/3 h-auto sm:w-[200px]"
                  />
                  <img
                    src={OhmGroupCar2}
                    alt="auto"
                    className="w-2/3 h-auto sm:w-[200px]"
                  />
                </div>
                <div className="pl-36 pt-6">
                  <img src={ReDefineButton} />
                </div>
              </div>

              <div className="flex-1 mr-8 sm:mr-16">
                <p className="text-left font-barlowsemicondensed text-4xl font-light text-[#197D71] pl-20">
                  OHM E Logistics Pvt. Ltd.
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  OHM E Logistics Pvt. Ltd. launches Eco Friendly (OHM Electric
                  Cabs) for GMR approved Airport to City service, Vice versa and
                  Intra-City Services. All our OHM Electric Cabs are
                  Comfortable, Tidy, Fresh, Hygienic and Emission-free. There is
                  a sizeable boot space for all your baggage. Just relax and
                  enjoy the music while we breeze to your location.
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  Currently we are providing services to Hyderabad and
                  Secunderabad only. Passengers can book Our OHM Electric Cabs
                  by downloading our user-friendly Android & IOS Mobile
                  Applications.
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  Each driver goes through a training program that includes soft
                  skills as well as Electric Vehicle operations and maintenance.
                  This is to ensure optimal customer satisfaction.
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  Our Pricing starts from 499/- for the first 35Kms and an extra
                  20/- per Km exclusive of GST, Parking and Toll Charges.
                  Passenger can pay at ease with our cashless payment modes like
                  UPI, Wallets, Debit - Credit Cards and Net Banking.
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  Passenger can pay at ease with our cashless payment modes like
                  UPI, Wallets, Debit - Credit Cards and Net Banking.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-40 pb-80">
        <div>
          <div
            className="w-full h-[500px] sm:h-[600px]"
            style={{
              backgroundImage: `url(${OhmGroupBgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex justify-center items-center mt-[-100px] sm:mt-[-120px] px-5 pt-32">
              <div className="flex-1 mr-8 sm:mr-16">
                <p className="text-left font-barlowsemicondensed text-4xl font-light text-[#197D71] pl-20">
                  OHM Charging Solutions
                </p>
                <p className="text-center sm:text-left pt-8 pl-20">
                  Ohm provides charging stations with a range of heavy duty or
                  special connectors that conform to the variety of standards.
                  For common DC rapid charging, multi-standard chargers equipped
                  with two or three of the Combined Charging System (CCS),
                  CHAdeMO, and AC fast charging.
                </p>
                <p className="text-center sm:text-left pt-8 pl-20">
                  <strong>Application</strong> <br />
                  <br />
                  1. EV drivers can search your charging station via our app
                  using location-based search. We also update your charging
                  space location as Google Maps that increases visibility and
                  invites more drivers.
                  <br />
                  <br />
                  2. You can set perunit rate or hourly rate in our app and
                  charge accordingly. Receive your payment through our simple
                  and easy payment gateways.
                  <br />
                  <br />
                  3. Track the no of electricity units used during each charge
                  through our app.
                  <br />
                  <br />
                  4. Use our app to find nearest EV charging space and charge
                  your vehicle worry free. Cashless transactions through wallet.
                  Connect your EV, charge pay and go.
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  <strong>Availability</strong> <br />
                  <br />
                  Our Chargers are significantly smaller and perfectly suited to
                  outdoor & space limited sites. Their installation is simpler,
                  faster and less expensive.
                  <br />
                </p>
                <p className="text-center sm:text-left pt-4 pl-20">
                  <strong>Compatability</strong> <br />
                  <br />
                  OHM EV Charger is easy to use with mobile application we
                  provide.Power ON/OFF with in your finger tips. Easy to use,
                  Easy to Charge.
                </p>
              </div>
              <div></div>

              <div className="flex-1 ml-8 sm:ml-16">
                <div className="relative">
                  {/* Car Image */}
                  <img
                    src={OhmChargingCar}
                    alt="auto"
                    className="w-full h-auto sm:w-[600px] sm:h-[500px] object-contain"
                    style={{ position: "relative", top: "-180px" }}
                  />

                  {/* Mobile Image */}
                  <img
                    src={OhmMobileImage}
                    alt="mobile"
                    className="w-auto h-auto mx-auto mt-[-150px]" // Adjust margin-top for positioning
                    style={{ position: "relative", top: "-80px" }}
                  />
                  <div className="">
                    <img src={ExploreButton} className="pl-36" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-40">
        <AppAvailable />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
