import React from "react";
import gallerybg from "../assets/galley-bg2.png";
import Firstpic from "../assets/Group 592.png";
import Secondpic from "../assets/Group 593 (1).png";
import Thirdpic from "../assets/Group 594 (1).png";
import Fourthpic from "../assets/Group 595 (1).png";
import Awards from './AwardsSection'



export default function GalleryScreen() {
  return (
    <div
      style={{
        backgroundImage: `url(${gallerybg})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        width: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",

        
      }}
      className="flex  flex-wrap justify-center items-center px-4 md:px-16"
    >
      {/* Left Section - Text Content */}
      <div className=" flex flex-row">
        <div className="flex flex-col w-full lg:w-1/2  mb-8 lg:mb-0 ">
        <span className="text-3xl md:text-[128px] text-left font-barlowcondensed tracking-normal font-light text-black leading-[153.6px]">
          Gallery
        </span>
        <span className="text-green-600 text-[24px]  font-barlowcondensed font-lightleading-[48px] md:text-[40px] text-left">
          From Vision to Revolution
        </span>
        <p className="text-gray-800 text-sm md:text-base w-[447px] font-roboto font-normal tracking-normal mt-4">
          OHM Automotive began with a simple but ambitious goal—to revolutionize
          the automotive industry by making sustainable transportation
          luxurious, efficient, and accessible. We started as pioneers in
          electric mobility, focusing on designing cutting-edge electric
          vehicles that blend advanced technology with environmental
          consciousness.
        </p>
        <div className="flex justify-start w-full mt-6">
          <div className="ml-0 flex pl-10 pr-10 items-center justify-center rounded-full border-2 border-green-500 bg-green-50 py-4 px-6  cursor-pointer hover:bg-green-100 transition duration-300 shadow-lg shadow-green-300">
            <div className="text-center text-[20px] font-roboto font-semibold text-black leading-[23.44px]">
              Explore the Future
            </div>
          </div>
        </div>
      </div>

      {/* Right Section - Image Grid */}
      <div className="w-full md:w-1/2 flex justify-center items-center md:pt-24">
        <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:grid-rows-2 gap-4 md:gap-0">
          <div className="  items-start justify-end pl-8 pt-7  ">
            <img src={Thirdpic} alt="Third " className="object-contain " />
          </div>
          <div className="flex items-end justify-start ">
            <img src={Firstpic} alt="First " className="object-contain " />
          </div>
          <div className="flex items-start justify-end">
            <img src={Fourthpic} alt="Fourth " className="object-contain " />
          </div>
          <div className="row-span-1">
            <img src={Secondpic} alt="Second " className="object-contain " />
          </div>
        </div>
      </div>
      
      
     </div>
    <div>
    
       
     </div>
       <Awards/>
    
      
    </div>
  );
}
