import React from "react";
import circle from "../assets/circle.png"


const Data = [
  {
    "Research and Innovation":
      "Continuously exploring new technologies, materials, and systems to advance EV performance, range, and cost-efficiency.",
  },
  {
    "Prototype Development":
      "Designing and creating physical prototypes to test the feasibility of new ideas and refine existing concepts.",
  },
  {
    "System Integration":
      "Ensuring that the various components of the EV (battery, motor, powertrain, software) work harmoniously together for optimal performance.",
  },
  {
    "Performance Testing":
      "Conducting rigorous testing on vehicles to evaluate power, range, safety, and durability under different conditions.",
  },
  {
    "Cost Optimization":
      "Identifying cost-effective solutions for manufacturing and scaling up production while maintaining high quality and performance standards.",
  },
  {
    "Regulatory Compliance":
      "As per Automotive Indian Standards (AIS) Key Areas of Focus",
  },
  {
    "Battery Technology":
      "Researching next-generation battery chemistries (solid-state, LFP, Graphene, ZNS etc.) for better energy density, faster charging, and longer life.",
  },
  {
    "Charging Infrastructure":
      "Developing faster and more efficient charging systems, including wireless charging and ultra-fast chargers.",
  },
  {
    "Autonomous Driving":
      "Integrating sensors, cameras, and AI for autonomous or semi-autonomous driving features such as lane-keeping, adaptive cruise control, and self-parking.",
  },
  {
    "Vehicle Design and Materials":
      "Innovating in the use of lightweight and sustainable materials, such as carbon fiber and aluminum, to improve the overall energy efficiency of the vehicle.",
  },
  {
    "Energy Management and Efficiency":
      "Optimizing the electric drivetrain to maximize performance",
  },
];

const DataArray = () => {
  return (
    <div className="">
     
      {Data.map((item, i) => {
        // Extracting the key and value from the object
        const [title, description] = Object.entries(item)[0];
        return (
          <div
            key={i}
            
          >
             
            <h3 className=' flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-2 font-normal' >
             <div className="flex flex-row gap-4">
             <img src={circle} alt="circle"className="w-[10px] h-[10px] mt-4"/> 
             <p>{title}:{description}</p>

             </div>
             
              </h3>
            {/* <p >{description}</p> */}
          </div>
        );
      })}
    </div>
  );
};

export default DataArray;
