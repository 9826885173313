import React from "react";
import Navbar from "./NavBar";
import Herobg2 from "../assets/cardcurve2.png";
import ContactAuto from "../assets/contactAutoImage.png";
//import Input from './Input';
import Header from "./Header";
const contact = () => {
  return (
    <>
      <Header />
      <div
        className="justify-center items-center flex flex-col "
        style={{
          backgroundImage: `url(${Herobg2})`,
          backgroundSize: "100% ",
          backgroundPosition: "left",
          //marginBottom: "100px !important",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full">
          <Navbar />
        </div>

        <div className="  flex flex-row justify-center align-center m-10">
          <div className="w-1/2 mt-24 ml-20">
            <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950  ">
              Contact Us
            </span>
            <p className="text-start text-[20px]  sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal ml-10">
              We’d Love to Hear from You!
            </p>
          </div>
          <div className="w-1/2 ">
            <div className=" flex flex-col item-center justify-start  mt-12">
              <img src={ContactAuto} alt="Nirmalsir" className="w-[600px] " />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default contact;
