import React, { useState } from "react";
import { FaTimes } from "react-icons/fa"; // Import the X icon
import ButtonImage from "../assets/loadingVector.png";
import Image1 from "../assets/galleryImages/image1.png";
import Image2 from "../assets/galleryImages/image2.jpeg";
import Image3 from "../assets/galleryImages/image3.jpeg";
import Image4 from "../assets/galleryImages/image4.jpeg";
import Image5 from "../assets/galleryImages/image5.jpeg";
import Image6 from "../assets/galleryImages/image6.png";
import Image7 from "../assets/galleryImages/image7.jpeg";
import Image8 from "../assets/galleryImages/image8.png";
import Image9 from "../assets/galleryImages/image9.jpeg";
import Image10 from "../assets/galleryImages/image10.jpeg";
import Image11 from "../assets/galleryImages/image11.png";
import Image12 from "../assets/galleryImages/image12.jpeg";
import Image13 from "../assets/galleryImages/image13.jpeg";
import Image14 from "../assets/galleryImages/image14.jpeg";
import Image15 from "../assets/galleryImages/image15.jpeg";
import Image16 from "../assets/galleryImages/image16.jpeg";
import Image17 from "../assets/galleryImages/image17.jpeg";
import Image18 from "../assets/galleryImages/image18.jpeg";
import Image19 from "../assets/galleryImages/image19.jpeg";

const allImages = [
  { src: Image1 },
  { src: Image2 },
  { src: Image3 },
  { src: Image4 },
  { src: Image5 },
  { src: Image6 },
  { src: Image7 },
  { src: Image8 },
  { src: Image9 },
  { src: Image10 },
  { src: Image11 },
  { src: Image12 },
  { src: Image13 },
  { src: Image14 },
  { src: Image15 },
  { src: Image16 },
  { src: Image17 },
  { src: Image18 },
  { src: Image19 },
];

export default function GalleryImages() {
  const [showAllImages, setShowAllImages] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const handleButtonClick = () => {
    setShowAllImages((prevState) => !prevState);
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const closeModal = (e) => {
    e.stopPropagation(); // Prevent event propagation to prevent closing modal unintentionally when clicking inside
    setCurrentImageIndex(null);
  };

  const nextImage = () => {
    if (currentImageIndex < allImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div className="p-4">
      {/* Gallery */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {(showAllImages ? allImages : allImages.slice(0, 11)).map(
          (item, index) => (
            <div
              key={index}
              className="overflow-hidden rounded-lg shadow-md w-full h-[334px] cursor-pointer"
              onClick={() => handleImageClick(index)}
            >
              <img
                src={item.src}
                alt={`Gallery Image ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
              />
            </div>
          )
        )}
        <div
          className="overflow-hidden rounded-lg shadow-md w-full h-[334px] flex items-center justify-center border p-2 cursor-pointer bg-green-custom"
          onClick={handleButtonClick}
        >
          <img
            src={ButtonImage}
            alt={showAllImages}
            className={`h-[80px] object-cover w-[50px] transition-transform duration-300 ${
              showAllImages ? "rotate-180" : "rotate-0"
            }`}
          />
          <span className="ml-2 text-lg font-semibold">{showAllImages}</span>
        </div>
      </div>

      {/* Modal for Image */}
      {currentImageIndex !== null && (
        <div className="fixed inset-0 flex justify-center items-center z-50 w-full h-full p-10">
          <div
            className="relative w-full h-full bg-black"
            onClick={closeModal} // Close the modal when background is clicked
          >
            {/* Close Button */}
            <FaTimes
              onClick={closeModal} // Close modal on X button click
              className="absolute top-4 right-4 text-white text-3xl cursor-pointer hover:text-red-500"
              title="Close"
            />
            <div className="relative w-full h-full flex justify-center items-center">
              <img
                src={allImages[currentImageIndex].src}
                alt={`Image ${currentImageIndex + 1}`}
                className="w-full h-full object-contain"
              />
            </div>
            {/* Prev and Next Buttons */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                prevImage();
              }}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2 rounded-full"
            >
              Prev
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                nextImage();
              }}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2 rounded-full"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
