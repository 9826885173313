import React from "react";
import Footer from "./Footer";
//import GalleryScreen from "./component/GalleryScreen";
import GalleryScreen from "./GalleryScreen";
import Innovations from "./Innovations";
import Header from "./Header";
import OurJourney from "./OurJourney";
import Partners from "./Partners";
import SuccessStories from "./SuccessStories";
import HeroSection from "./HeroSection";
import { ArrowComponent } from "./ArrowComponent";
import { AppAvailable } from "./AppAvailable";

const DashBoard = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <ArrowComponent />
      <OurJourney />
      {/* <SuccessStories /> */}
      <GalleryScreen />
      <Innovations />
      <Partners />
      <AppAvailable />
      <Footer />
    </div>
  );
};
export default DashBoard;
