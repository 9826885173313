import React from "react";
import innovationsbg from "../assets/innovationsbg.png";
import EvTrans from "../assets/ohmgroup-automotive.png";
import EvLogistics from "../assets/ohmgroup-elogistics.png";
import Evcharging from "../assets/ohmgroup-charging.png";

export default function Innovations() {
  return (
    <div
      style={{
        backgroundImage: `url(${innovationsbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        paddingBottom: "25px",
      }}
      className="flex flex-col justify-between px-20"
    >
      {/* Heading */}
      <p className="text-[32px] md:text-[96px] text-center font-barlowcondensed font-light leading-[115px]">
        OHM Group of Companies
      </p>
      <p className="text-[#0AAC76] text-[24px] md:text-[40px] text-center font-barlowcondensed font-normal leading-[30px]">
        Innovations
      </p>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row justify-evenly pt-12 md:pt-20 space-y-8 md:space-y-0 ">
        {/* EV Transportation */}
        <div className="flex flex-wrap align-center justify-center  space-x-28">
        <div className="flex flex-col items-center justify-center ">
          <img src={EvTrans} alt="ohm-automotive-auto"/>
          <span className="text-[#173D3D] tracking-normal  mt-2 text-center font-roboto font-normal text-[20px] leading-[20px]">
            OHM Automotive Pvt. Ltd.
          </span>
          <span className=" text-center font-roboto font-normal tracking-normal  text-[20px] leading-[23.36px]">
            EV Transportation
          </span>
        </div>

        {/* EV Logistics */}
        <div className="flex flex-col items-center  ">
        <img src={EvLogistics} alt="ohm-logistics-auto"/>
          <span className="text-[#173D3D]  mt-4 text-center font-roboto font-normal tracking-normal  text-[20px] leading-[20px]">
            OHM E Logistics Pvt. Ltd.
          </span>
          <span className=" text-center font-roboto font-normal tracking-normal  text-[20px] leading-[23.36px]">
            Best EV Cab Services
          </span>
        </div>

        {/* EV Charging Solutions */}
        <div className="flex flex-col items-center">
        <img src={Evcharging} alt="ohm-charging station"/>
          <span className="text-[#173D3D]  mt-2 text-center font-roboto font-normal tracking-normal leading-[20px] text-[20px] ">
            OHM Charging Solutions Pvt. Ltd.
          </span>
          <span className=" text-center font-roboto font-normal tracking-normal  text-[20px] leading-[23.36px]">
            One-stop EV Charging Solution
          </span>
        </div>
      </div>
        </div>
        
    </div>
  );
}
