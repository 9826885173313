import React from "react";
import footerImage from "../assets/foooterimage.png"; // Corrected spelling of the image file
import Address from "../svgs/address-icon";
import Mail from "../svgs/mail-icon";
import Service from "../svgs/service-icon";

export default function Footer() {
  return (
    <>
      {/* <Application /> */}
      <div
        style={{
          backgroundImage: `url(${footerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "20px",
          backgroundColor: "#333333",
          color: "white",
          width: "100%",
        }}
        className="flex  flex-wrap justify-center items-center"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
            width: "100%",
          }}
          className="container"
        >
          <div className="flex flex-col md:flex-row justify-evenly space-x-0 md:space-x-8 w-full  ">
            {/* About Section */}
            <div className="bg-transparent md:w-[546px] w-full p-8">
              <p className="text-[#F1BF1C] pb-3 text-[36px] md:text-[120px] font-barlowcondensed tracking-normal font-light leading-[115px]">
                About
              </p>
              <p className="md:text-[16px]  tracking-normal font-roboto font-normal leading-[18px] ">
                Electric cars are predicted to be the next disruptive market
                force for transportation and technology. They have the potential
                to revolutionize how energy is used, created, and redirected.
                One of the primary reasons for the introduction of electric cars
                into the market is the concern over greenhouse gas emissions and
                their contribution to global warming.
                <p className="py-2 font-nova-light leading-[18px] text-[16px]">
                  E-Cargo Vehicles fully understand the world of logistics is
                  changing. Last mile delivery brings with it its own
                  challenging problems: emissions levels, congestion, cost,
                  reliability, and ever-demanding client deadlines.
                </p>
              </p>
              <div className="flex justify-start w-full mt-4">
                <div className="ml-[7px] flex w-[236px] items-center justify-center rounded-[31px] border-2 border-solid border-yellow-500 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-sm shadow-gray-50">
                  <div className="font-roboto text-center text-lg font-semibold leading-[normal] text-white ">
                    More Info
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Section */}
            <div className="bg-transparent md:w-1/3 p-5">
              <p className="text-[#F1BF1C] text-[36px] pb-5 md:text-[120px] font-barlowcondensed tracking-normal font-light leading-[115px]  ">
                Contact
              </p>
              <div className="flex">
                {/* Icons column */}
                <div className="flex flex-col items-start space-y-10">
                  {" "}
                  {/* Vertical icons */}
                  <Address />
                  {/* <Service /> */}
                  <Mail />
                </div>

                {/* Content column */}
                <div className="flex flex-col ml-4 space-y-10 text-[16px] font-roboto font-normal leading-[18px]">
                  {" "}
                  {/* Vertical text with space between */}
                  <p>
                    H.O, 20B, ASCI Campus, Road No 5, <br />
                    Banjara Hills, Hyderabad - 500034.
                  </p>
                  {/* <p>
                    Sales: <a href="tel:+918142033344">+91 81420 33344</a>{" "}
                    <br />
                    Service: <a href="tel:+918142033388">+91 81420 33388</a>
                  </p> */}
                  <p>
                    <a href="mailto:sales@ohmautomotives.com">
                      sales@ohmautomotives.com
                    </a>
                    <br />
                    <a href="mailto:support@ohmautomotives.com">
                      support@ohmautomotives.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Terms & Privacy Policy */}
          <div className="w-full h-[120px]  text-center mt-4 ">
            <div className="border-t   border-[#4E4E4E] " />
            <div className="text-[#F1BF1C] mt-10">
              <a
                href="/terms"
                className="font-roboto font-normal leading-[37.05px]"
              >
                Terms & Conditions
              </a>{" "}
              |{" "}
              <a
                href="/privacy"
                className="font-roboto font-normal leading-[37.05px]"
              >
                Privacy Policy
              </a>
            </div>
            <p className="text-white font-roboto font-normal leading-[10.05px]">
              OHM Automotive Pvt. Ltd. | Copyright © 2024. All Rights Reserved®
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
