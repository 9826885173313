import React from 'react'
import Herobg2 from "../assets/cardcurve2.png";
//import FrontAuto from "../assets/whiteAutoFront.png"
import Navbar from "./NavBar";
//import Gllery from "../assets/Gallery.png"
import LeafAuto from "../assets/lefauttop 1.png"
import ProductContent from './ProductContent';
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";
import Header from './Header';
 const Product = () => {
  return (
    <>
    <Header/>
        <div>
      <div  className="justify-center items-center flex flex-col "  
    style={{
      backgroundImage: `url(${Herobg2})`,
      backgroundSize: "100% ",
      backgroundPosition: "left",
       //marginBottom: "100px !important",
      backgroundRepeat: "no-repeat",
      
    }}
    >
        
        <div className="w-full">
        <Navbar />
      </div>

    <div className='  flex flex-row justify-center align-center  gap-32 m-10'>
      <div className='w-1/2 mt-24 ml-20'>
        <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950  ">Products</span>
        <p className="text-start text-[20px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">Innovation in Every Mile</p>
        
        
      </div>
      <div className="w-1/2 ">
      <div className=' flex flex-col item-center justify-start  mt-6'>
       <img src={LeafAuto} alt='Nirmalsir' className="w-[500px] " />
        
      </div>


    </div>
   
    </div>
    
    
 
      
   </div>
  
   <div className="w-full flex justify-center ">
  <div className="w-full max-w-[1000px] px-6 mx-4 mt-20 text-center sm:text-start text-[20px] sm:text-[30px] leading-[36px] font-barlowcondensed font-light italic text-gray-600 flex flex-col">
    <p className="font-light">“</p>
    <p>
      Ditch the gas guzzler and experience the power, efficiency, and environmental responsibility of an electric three-wheeler. With its impressive range, advanced features, and sustainable design, the 3WEV is the perfect vehicle for those looking to reduce their carbon footprint without sacrificing performance or practicality.
    </p>
    <p>
      Upgrade your commute today and drive towards a greener tomorrow.
    </p>
   
    <div className="flex justify-end mr-40  ">
      <p>“</p>
    </div>
  </div>
</div>

  
   <div className='mt-20'>
    <ProductContent/>
   </div>
   <div>
    <AppAvailable/>
   </div>
   <div>
    <Footer/>
   </div>
   
    </div>

    </>
      )
}
export default Product
