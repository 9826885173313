import React from "react";
import playstore from "../assets/googleplaystore-img2.png";
import appstore from "../assets/appleappstore-img.png";
import appimg from "../assets/app-img.png";

export const AppAvailable = () => {
  return (
    <div className="flex flex-col items-center justify-evenly bg-custom-gradient">
      <div className="flex flex-row gap-20 items-center justify-between">
        <div className="flex flex-col items-center justify-around">
          <div>
            <p className="font-barlowcondensed tracking-normal font-normal text-[30px] md:text-[70px] text-[#FFFFFF] leading-[105px]">
              OHM electric cabs
            </p>
            <p className="font-barlowcondensed tracking-normal font-light text-[15px] md:text-[25px] w-[420px] text-[#FFFFFF] leading-[39px]">
              Experience Luxury, The Green Way with OHM Electric Cabs!
            </p>
          </div>
          <div className="container flex flex-wrap mt-8">
            {/* Link for Play Store */}
            <a
              href="https://play.google.com/store/search?q=ohm+electric+cabs&c=apps"
        
            >
              <img
                src={playstore}
                alt="playstore"
                className="md:w-[212px] md:h-[67px]"
              />
            </a>
            {/* Link for App Store */}
            <a
              href="https://apps.apple.com/us/app/ohm-electric-cabs/id1668975054"
            >
              <img
                src={appstore}
                alt="appstore"
                className="md:w-[212px] md:h-[67px]"
              />
            </a>
          </div>
        </div>
        <div>
          <img src={appimg} alt="appimg" className="w-[368px] h-[402px]" />
        </div>
      </div>
    </div>
  );
};
