import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./component/About";
import Product from "./component/Product";
import Gallery from "./component/Gallery";
import ResearchDEvelopment from "./component/ResearchDevelopment";
import ContactUs from "./component/ContactUs";
import CsrPage from "./component/CsrPage";
import OhmGroup from "./component/OhmGroup";
import DashBoard from "./component/DashBoard";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<DashBoard />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Product />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/ohmgroup" element={<OhmGroup />} />
          <Route path="/csr" element={<CsrPage />} />
          <Route
            path="/research-and-development"
            element={<ResearchDEvelopment />}
          />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </Router>
    </div>
  );
}
