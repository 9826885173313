import React from 'react'
import Bulb from "../assets/BulbImage.png"
import Herobg2 from "../assets/cardcurve2.png";
import Navbar from "./NavBar";
import ResearchContent from './ResearchContent';
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";
import Header from './Header';
 const ResearchDevelopment = () => {
  return (
    <>
    <Header/>
        <div>
      <div  className="justify-center items-center flex flex-col "  
    style={{
      backgroundImage: `url(${Herobg2})`,
      backgroundSize: "100% ",
      backgroundPosition: "left",
       //marginBottom: "100px !important",
      backgroundRepeat: "no-repeat",
      
    }}
    >
        
        <div className="w-full">
        <Navbar />
      </div>

    <div className='  flex flex-row justify-center align-center mt-8 '>
      <div className='w-1/2 mt-24 ml-44'>
        <span className="font-barlowcondensed tracking-light text-[120px] font-light leading-[100.6px] text-cyan-950  ">Research & Development</span>
        <p className="text-start text-[20px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal mt-6">Creating What’s Next</p>
        
        
      </div>
      <div className="w-1/2 ">
      <div className=' flex flex-col item-center justify-start  '>
       <img src={Bulb} alt='Bulbimage' className="w-[452px] " />
        
      </div>


    </div>
       
    </div>
      
   </div>
   <div>
    <ResearchContent/>
   </div>
   <div className="mt-10">
    <AppAvailable/>
   </div>
   <div>
    <Footer/>
   </div>
  
   
  

    </div>
    </>

    
  )
}
export default ResearchDevelopment
