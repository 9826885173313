import React from 'react'
import Herobg2 from "../assets/cardcurve2.png";
//import FrontAuto from "../assets/whiteAutoFront.png"
import Navbar from "./NavBar";
import Gllery from "../assets/Gallery.png"
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";
import GalleryImages from './GalleryImages';
import Header from './Header';
  const Gallery = () => {
  return (
    <>
    <Header/>
    <div>
      <div  className="justify-center items-center flex flex-col "  
    style={{
      backgroundImage: `url(${Herobg2})`,
      backgroundSize: "100% ",
      backgroundPosition: "left",
       //marginBottom: "100px !important",
      backgroundRepeat: "no-repeat",
      
    }}
    >
        
        <div className="w-full">
        <Navbar />
      </div>

    <div className='  flex flex-row justify-center align-center  m-10'>
      <div className='w-1/2 mt-24 mr-48'>
        <span className="font-barlowcondensed tracking-light text-[128px] font-light leading-[153.6px] text-cyan-950  ">Gallery</span>
        <p className="text-start text-[20px] w-[500px] sm:text-[40px] leading-[48px] text-green-500 font-barlowcondensed font-normal">From Vision to Revolution</p>
        
        
      </div>
      <div className="w-1/2 ">
      <div className=' flex flex-col item-center justify-start  mt-14'>
       <img src={Gllery} alt='gallery' className="w-[345px] " />
        
      </div>


    </div>
   
    </div>
    
    
 
      
   </div>
   <div className='flex justify-center items-center'>
    <div className='w-[1100px]'>
    <GalleryImages/>
    </div>
    

   </div>
   <div>
    <AppAvailable/>

   </div>
   <div>
    <Footer/>
   </div>
   

    </div>
    
    </>
  
  )
}
export default Gallery
