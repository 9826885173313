import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Importing the images correctly
import Csr3 from "../assets/CSR_images/Csr3.jpeg";
import Csr1 from "../assets/CSR_images/Csr1.jpeg";
import Csr2 from "../assets/CSR_images/Csr2.jpeg";
import Csr4 from "../assets/CSR_images/Csr4.jpeg";
import Csr5 from "../assets/CSR_images/Csr5.jpeg";
import Csr6 from "../assets/CSR_images/Csr6.jpeg";
import Csr7 from "../assets/CSR_images/Csr7.jpeg";
import Csr8 from "../assets/CSR_images/Csr8.jpeg";
import Csr9 from "../assets/CSR_images/Csr9.jpeg";
import Csr10 from "../assets/CSR_images/Csr10.jpeg";
import Csr11 from "../assets/CSR_images/Csr11.jpeg";
import Csr12 from "../assets/CSR_images/Csr12.jpeg";
import Csr13 from "../assets/CSR_images/Csr13.jpeg";
import Csr14 from "../assets/CSR_images/Csr14.jpeg";
import Csr15 from "../assets/CSR_images/Csr15.jpeg";
import Csr16 from "../assets/CSR_images/Csr16.jpeg";

export default function CsrPage() {
  const [activeIndex, setActiveIndex] = useState(0);

  // Images for the carousel
  const images = [
    { src: Csr3, alt: "CSR Image 3" },
    { src: Csr1, alt: "CSR Image 1" },
    { src: Csr2, alt: "CSR Image 2" },
    { src: Csr4, alt: "CSR Image 4" },
    { src: Csr5, alt: "CSR Image 5" },
    { src: Csr6, alt: "CSR Image 3" },
    { src: Csr7, alt: "CSR Image 1" },
    { src: Csr8, alt: "CSR Image 2" },
    { src: Csr9, alt: "CSR Image 4" },
    { src: Csr10, alt: "CSR Image 5" },
    { src: Csr11, alt: "CSR Image 3" },
    { src: Csr12, alt: "CSR Image 1" },
    { src: Csr13, alt: "CSR Image 2" },
    { src: Csr14, alt: "CSR Image 4" },
    { src: Csr15, alt: "CSR Image 5" },
    { src: Csr16, alt: "CSR Image 5" },
  ];

  // Handlers for changing the active index
  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="flex justify-center items-center py-10 w-full max-w-[80%]">
      {/* Reduced width */}
      <div className="w-full">
        {/* Previous Button */}

        {/* Image Display with Flex */}
        <div className="flex gap-10 justify-center items-center">
          <button
            onClick={handlePrev}
            className="w-12 h-12 bg-white text-black rounded-full shadow-md flex items-center justify-center opacity-75 hover:bg-gray-300 transition-all duration-300"
          >
            <FaChevronLeft className="text-black h-6 w-6" />
          </button>
          <div className="">
            <img
              src={images[activeIndex].src}
              alt={images[activeIndex].alt}
              className="max-w-[700px] w-full object-contain border-8 rounded-2xl shadow-lg"
            />
          </div>

          {/* Corrected Next Button */}
          <button
            onClick={handleNext}
            className="w-12 h-12 bg-white text-black rounded-full shadow-md flex items-center justify-center opacity-75 hover:bg-gray-300 transition-all duration-300"
          >
            <FaChevronRight className="text-black text-2xl" />
          </button>
        </div>

        {/* Image Selector Buttons */}
        <div className="flex justify-center mt-4 space-x-2">
          {images.map((image, index) => (
            <button
              key={index}
              onClick={() => handleClick(index)}
              className={`w-3 h-3 rounded-full border-2 ${
                activeIndex === index
                  ? "bg-black border-black" // Black when selected
                  : "border-green-700" // Green when not selected
              } transition-all`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
