import React from 'react'
import  { useEffect, useState } from 'react';
import DownArrow from "../svgs/downarrow";
import Footer from './Footer'
export const ArrowComponent = () => {
    const [show, setShow] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setShow(true);
    } else {
      if(window.scroll===<Footer/>){
        setShow(false);
      }
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    show && (
        <div className=" justify-center items-center flex flex-col gap-4 p-8 sticky top-0 z-50">
              <div className="flex flex-col items-center animate-bounce ">
              {show && <DownArrow />} {/* Show your content when 'show' is true */}
             
              </div>
               <span className=" text-[16px] text-center font-nova leading-[16px] ">Scroll down</span>
        </div>
     
    )
  );
};

 


       