import React from 'react'
import Contact from './contact'
import Input from "./Input"
import { AppAvailable } from "./AppAvailable";
import Footer from "./Footer";


const ContactUs = () => {
  return (
   <>
   <Contact/>
   <div className='flex justify-center items-center'>
    <div className='w-[1100px]'>
    <Input/>
    </div>
   </div>
   
   <div className='mt-12'>
   <AppAvailable/>

   </div>
  
   <Footer/>

   
   
   </>

  )
}

export default ContactUs
