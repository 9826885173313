import React, { useState } from "react";
import logo from "../assets/logo.png";
//import { NavLink } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // To navigate programmatically

  const links = [
    { name: "About", to: "/about" },
    { name: "Products", to: "/products" },
    { name: "Research & Development", to: "/research-and-development" },
    { name: "Gallery", to: "/gallery" },
    // { name: "Leadership", to: "/leadership" },
    { name: "Ohmgroup", to: "/ohmgroup" },
    { name: "CSR", to: "/csr" },
    { name: "Contact", to: "/contact" },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className="max-w-6xl mx-auto p-5 sm:px-6 lg:px-18">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex items-center">
            <NavLink to="/">
              <img
                className="h-24 w-auto cursor-pointer"
                src={logo}
                alt="Logo"
              />
            </NavLink>
          </div>

          {/* Mobile menu button */}
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              className="text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden sm:flex">
            <div className="flex space-x-4">
              {links.map((link, index) => (
                <NavLink
                  key={index}
                  to={link.to}
                  className={({ isActive }) =>
                    `text-black px-3 mt-8 rounded-sm text-sm font-medium uppercase tracking-wider ${
                      isActive
                        ? "border-b-[2px] border-green-500"
                        : "border-b-[2px] border-transparent"
                    } transition duration-200`
                  }
                >
                  {link.name}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {links.map((link, index) => (
              <NavLink
                key={index}
                to={link.to}
                onClick={() => setIsOpen(false)} // Close menu on click
                className={({ isActive }) =>
                  `text-black block px-3 py-2 rounded-md text-base font-medium uppercase tracking-wider ${
                    isActive ? "font-bold text-green-500" : ""
                  }`
                }
              >
                {link.name}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
