import React from "react";
import circle from "../assets/circle.png"


const productArray = [
    
  {
    "Advanced Suspension Systems":
      "Many three wheelers are equipped with superior suspension systems that absorb shocks, making the ride smoother even on rough terrains.",
  },
  {
    " Engine Technology":
      "Modern engines are designed to minimize noise while maximizing efficiency. This results in a quieter cabin, allowing for a more relaxed driving experience.",
  },
  {
    "Ample Cargo Space":
      "Store luggage, packages, or equipment securely, ensuring a clutter-free passenger area.",
  },
  
];

const productContentArray = () => {
  return (
    <div className="">
     
      {productArray.map((item, i) => {
        // Extracting the key and value from the object
        const [title, description] = Object.entries(item)[0];
        return (
          <div
            key={i}
            
          >
             
            <h3 className='flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-2 font-normal' >
             <div className="flex flex-row gap-10">
             <img src={circle} alt="circle"className="w-[10px] h-[10px] mt-4"/> 
             <p>{title}:{description}</p>

             </div>
             
              </h3>
            {/* <p >{description}</p> */}
          </div>
        );
      })}
    </div>
  );
};

export default productContentArray;
