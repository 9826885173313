import React from "react";
import text from "../assets/Text.png";

const Input = () => {
  return (
    <div className="flex flex-row  ">
      <div className="flex w-[580px] flex-col mt-20">
        <div className="flex flex-row justify-end  gap-4 mb-4">
          <input
            type="text"
            placeholder="Enter Your Name"
            className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
          />

          <input
            type="text"
            placeholder="Enter Your Email"
            className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none  focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
          />
        </div>
        <div className="flex flex-row justify-end gap-4">
          <input
            type="text"
            placeholder="Enter Your Mobile Number"
            className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
          />

          <input
            type="text"
            placeholder="Select Your City"
            className="w-full sm:w-[350px] px-4 py-2 border border-green-custom rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
          />
        </div>
        <div className="mt-8 w-full">
          <textarea
            placeholder="Enter your message"
            className=" sm:w-full h-[140px] px-2 py-2 border border-green-custom rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent text-gray-700 placeholder-gray-400 shadow-lg"
          />
        </div>

        <div className="flex flex-row gap-4 mt-4 ">
          <div className="flex justify-start w-full mt-4 ">
            {/* <div className="rounded-[31px] bg-green-50  p-2 "> */}
            <div className="ml-[2px] flex  items-end  justify-center rounded-[31px] border-2 border-solid  border-green-custom bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-lg ">
              <div className="font-roboto  pl-10 pr-10  text-center text-[20px] font-medium leading-[18px]">
                Submit
              </div>
            </div>
          </div>
          <div className="flex justify-start w-full mt-4 mr-48">
            <div className="ml-[2px] flex  items-end  justify-center rounded-[31px] border-2 border-solid  border-green-custom bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[18px] shadow-lg">
              <div className="font-roboto  pl-10 pr-10  text-center text-[20px] font-medium leading-[18px]">
                Reset
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 flex flex-col justify-center items-center ">
        <div className="w-[300px] h-[380px] border mt-20  mr-28 bg-custom-gradients flex flex-col justify-start items-center rounded-lg ">
          <div className="flex flex-col align-center justify-center ">
            <div className="flex justify-center mb-4">
              <img
                src={text}
                alt="textMessage"
                className="w-[80px] leading-[38px] font-medium mt-8"
              />
            </div>

            <p className="font-barlowcondensed text-[32px]">
              Subscribe our Newsletter
            </p>
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter Your Email"
              className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:bg-green-custom focus:border-transparent text-gray-700 placeholder-gray-400 mt-4"
            />
          </div>

          <div className="flex justify-center w-full mt-4 ">
            <div className="ml-[2px] flex  items-cenetr  justify-center rounded-[10px] border-2 border-solid  border-green-custom bg-green-50 pb-[17px] pl-[22px] pr-6 pt-[12px] shadow-sm shadow-green-300 mt-2">
              <div className="font-roboto  pl-20 pr-20  text-center text-[20px] font-medium leading-[18px]">
                Subscribe
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Input;
