import React, { useState, useEffect } from "react";
import auto from "../assets/smallAuto.png";
import whiteAuto from "../assets/produAuto.png";
import goodsauto from "../assets/goods-auto.png";
import BlueAuto from "../assets/BlueAuto.png";
import ProductContentArray from "./ProductContentArray";
import Line from "../assets/horizaltalline.png";
import horizontalline from "../assets/line.png";
import Product from "../assets/productbg.png";
import RightArrow from "../assets/RightArrow.png";
import LeftArrow from "../assets/LeftArrow.png";
import FrontRace from "../assets/RaceXL/front.png";
import SideRace from "../assets/RaceXL/side-view.png";
import InsideRace from "../assets/RaceXL/inside.png";
import BackRace from "../assets/RaceXL/back.png";
import FrontPace from "../assets/Pace/front.png";
import SidePace from "../assets/Pace/sideview.png";
import InsidePace from "../assets/Pace/inside.png";
import BackPace from "../assets/Pace/back.png";

const WhiteAutoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("race");
  const [showData, setShowData] = useState(null);

  const raceImages = [whiteAuto, whiteAuto, whiteAuto, whiteAuto];
  const paceImages = [BlueAuto, BlueAuto, BlueAuto, BlueAuto];
  const raceMiniImages = [FrontRace, SideRace, InsideRace, BackRace];
  const paceMiniImages = [FrontPace, SidePace, InsidePace, BackPace];

  const autoImages = activeButton === "race" ? raceImages : paceImages;
  const miniArray = activeButton === "race" ? raceMiniImages : paceMiniImages;

  const clickpluse = (id) => {
    setShowData((prev) => (prev === id ? null : id));
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleMiniAutoClick = (miniAutoIndex) => {
    setCurrentIndex(miniAutoIndex);
  };

  // Handle Right Arrow Click
  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % miniArray.length);
  };

  // Handle Left Arrow Click
  const handleLeftArrowClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? miniArray.length - 1 : prevIndex - 1
    );
  };

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % miniArray.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [miniArray]);

  return (
    <div>
      <div
        className="flex flex-col justify-center items-center"
        style={{
          backgroundImage: `url(${Product})`,
          backgroundSize: "100% ",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex w-[1100px]">
          <div className="w-1/2">
            <button
              onClick={() => {
                setActiveButton("race");
                setCurrentIndex(0); // Reset carousel index when switching
              }}
              className={`w-full p-4 text-left ${
                activeButton === "race"
                  ? "bg-green-customs-600 text-white"
                  : "border-b-2 border-green-customs-600"
              }`}
            >
              <p className="flex justify-center font-racingsansone">
                <span
                  className={`${
                    activeButton === "race"
                      ? "bg-green-customs-600 text-white"
                      : "text-green-customs-600"
                  }`}
                >
                  RACE xL -
                </span>{" "}
                <span
                  className={`${
                    activeButton === "race"
                      ? "bg-green-customs-600 text-black"
                      : "text-gray-400"
                  }`}
                >
                  Passenger
                </span>{" "}
                <span
                  className={`${
                    activeButton === "race"
                      ? "bg-green-customs-600 text-black"
                      : "text-black"
                  }`}
                >
                  Vehicle
                </span>
              </p>
            </button>
          </div>
          <div className="w-1/2">
            <button
              onClick={() => {
                setActiveButton("pace");
                setCurrentIndex(0);
              }}
              className={`w-full p-4 text-left ${
                activeButton === "pace"
                  ? "bg-green-customs-600 text-white"
                  : "border-b-2 border-green-customs-600"
              }`}
            >
              <p className="flex justify-center font-racingsansone">
                <span
                  className={`${
                    activeButton === "pace"
                      ? "bg-green-customs-600 text-white"
                      : "text-green-customs-600"
                  }`}
                >
                  PACE -
                </span>{" "}
                <span
                  className={`${
                    activeButton === "pace"
                      ? "bg-green-customs-600 text-black"
                      : "text-gray-400"
                  }`}
                >
                  Cargo
                </span>{" "}
                <span
                  className={`${
                    activeButton === "pace"
                      ? "bg-green-customs-600 text-black"
                      : "text-black"
                  }`}
                >
                  Vehicle
                </span>
              </p>
            </button>
          </div>
        </div>
        <div className="mx-5"></div>

        <div className="flex flex-row justify-center mx-52">
          <div className="pt-72 cursor-pointer" onClick={handleLeftArrowClick}>
            <img src={LeftArrow} alt="Left Arrow" />
          </div>
          <img
            src={miniArray[currentIndex]}
            alt="Auto"
            className="mb-4 lg:mb-0 object-contain w-full  h-auto"
          />

          <div className="pt-72 cursor-pointer" onClick={handleRightArrowClick}>
            <img src={RightArrow} alt="Right Arrow" />
          </div>
        </div>
        <div className="flex justify-center gap-4 flex-wrap mt-6">
          {miniArray.map((item, i) => (
            <img
              key={i}
              src={item}
              alt={`Mini Auto ${i + 1}`}
              onClick={() => handleMiniAutoClick(i)}
              className={`border-2 rounded-lg border-white w-[166px] h-[100px] bg-white px-4 py-2 cursor-pointer ${
                currentIndex === i ? "ring-2 ring-[#f4f3ef]" : ""
              }`}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-row mx-52 justify-center mt-12 h-[127px] gap-10 w-[926px] border rounded-lg shadow-lg item-center ">
          <div className="flex flex-row w-[700px]  gap-4">
            <p className="font-bold mr-2">
              <img
                src={Line}
                alt="line"
                className="h-[87px] ml-6 w-[10px] mt-6"
              />
            </p>
            <p className="flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-6 font-normal mt-8">
              In today's fast-paced world, logistics and transportation play a
              pivotal role in the success of businesses. When it comes to
              transporting goods efficiently, three wheeler cargo vehicles have
              emerged as an optimal choice for many. We will explore the
              benefits of three wheeler cargo vehicles that offer good driving
              space and a smooth, noiseless experience while carrying heavy
              weights.
            </p>
          </div>
          <div className="flex flex-col w-[226px] ml-10 ">
            <p className="font-bold pt-6 text-black ">Single charge</p>

            <div className="flex text-green-customs-600">
              <p className="text-3xl lg:text-5xl  font-barlowsemicondensed font-normal leading-[60.2px]">
                170
              </p>
              <p className="text-sm lg:text-[40px] items-end flex font-barlowsemicondensed font-normal text-[40px] leading-[48px]">
                +miles
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="  flex justify-center items-center">
        <p className="  w-[920px] mx-52 mt-10 text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-6 font-normal">
          The dashboard of a three-wheeler electric vehicle (3WEV) is
          thoughtfully designed to prioritize functionality and
          user-friendliness, ensuring the driver has quick access to all the
          essential information and controls they need. At the heart of this
          intuitive dashboard is a sleek, state-of-the-art smart LED display
          that provides the driver with a wealth of real-time data in a clear,
          easy-to-read format. With a vibrant, high-resolution screen, this
          display keeps the driver informed on crucial metrics like battery
          level, motor output, and current speed, allowing them to monitor the
          vehicle's performance and optimize their driving for maximum
          efficiency. But the dashboard's smart features don't stop there - it
          also incorporates a convenient USB charging port, enabling drivers to
          keep their mobile devices powered up and connected on the go. And for
          those who appreciate the tactile experience of manual control, the
          3WEV's dashboard features a straightforward, ergonomic gear shifter
          that makes changing gears a breeze, providing a seamless and
          responsive driving experience. Thoughtfully designed to put all the
          necessary information and controls right at the driver's fingertips,
          the 3WEV dashboard truly embodies the perfect blend of form and
          function, ensuring a safe, efficient, and enjoyable ride every time.
        </p>
      </div>

      {activeButton === "race" ? (
        <div className="flex flex-col justify-center items-center">
          <div className="mx-52 w-[920px]">
            <p className="font-bold">Conquer Hills with Ease</p>
            <p className="flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-6 font-normal">
              Say goodbye to the anxiety of navigating steep hills. Our 3WEV
              features a hill hold function that provides an extra boost of
              power when you need it most. Experience a smooth, secure ride no
              matter the terrain, and arrive at your destination feeling
              confident and energized.
            </p>
          </div>
          <div className="mx-52 w-[920px]">
            <p className="font-bold">Spacious Seating</p>
            <p className="flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-6 font-normal">
              Comfortably accommodate a driver and three passengers, making it
              ideal for carpooling, team transportation, or family outing
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <div className="mx-52 w-[920px]">
            <p className="font-bold">Why Choose Three Wheeler Cargo?</p>
            {/* Section 1 */}
            <div>
              <div className="flex justify-between items-center mt-6">
                <p>Spacious Driving Environment</p>
                <button className="text-[25px]" onClick={() => clickpluse(1)}>
                  {showData === 1 ? "-" : "+"}
                </button>
              </div>
              {showData === 1 && (
                <p className="mt-2">
                  <ProductContentArray />
                </p>
              )}
              <hr className="mt-4 bg-black"></hr>
            </div>

            <div>
              <div className="flex justify-between items-center mt-6">
                <p>Smooth and Noiseless Ride</p>
                <button className="text-[25px]" onClick={() => clickpluse(2)}>
                  {showData === 2 ? "-" : "+"}
                </button>
              </div>
              {showData === 2 && (
                <p className="mt-2">
                  <ProductContentArray />
                </p>
              )}
              <hr className="mt-4 bg-black"></hr>
            </div>
          </div>
        </div>
      )}
      <div className="  flex justify-center items-center ">
        <div className="mx-52 mb-6 w-[920px]">
          <img src={horizontalline} alt="line" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default WhiteAutoCarousel;
