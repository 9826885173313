import React from 'react'
 const Data =[
    {"01":"Power train"},
    {"02":"High-Capacity Passenger"},
    {"03":"Battery"},
    {"04":"Charging stations & many"},
    {"05":"Cargo Vehicles"}
 ]
 const SmallCards = () => {
  return (
    <div className='flex flex-wrap gap-1 '>
        {
            Data.map((item,i)=>{
                const [title,content] = Object.entries(item)[0]
                return (
                    <div
                    key={i}
                    className="border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition bg-white flex flex-row"
                  >
                    <p className="text-3xl font-bold text-green-custom mb-2  ">{title}</p>
                    <p className="text-lg text-gray-700 ml-2 w-[130px]  ">{content}</p>
                  </div>)
            })
        }
    </div>
  )
}
export default SmallCards
