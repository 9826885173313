import React from 'react'
import Line from "../assets/line.png"
import Whatsup from "../assets/whatsupImage.png"
import KarnaImg from "../assets/karna.png"
import LInkDin from "../assets/linkedin.png"
import Instagram from "../assets/insta.png"
import Data from "./DataArray"
// import { AppAvailable } from "./AppAvailable";
// import Footer from "./Footer";
import SmallCards from './SmallCards'


 const ResearchContent = () => {
  return (
    <div className=' mt-8'>
        <div className='flex justify-center items-center'>
        <div className=' w-[1100px] flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-[14px] md:text-base  font-normal m-10'>
            <p>
            Founded in mid-2020, OHM R&D has embraced the age-old adage,
             “Necessity is the mother of invention,” to fuel its innovative drive in the electric vehicle (EV) sector. 
             At a time when the automotive industry faced unprecedented challenges, 
             our team harnessed the power of creativity and ingenuity to develop sophisticated solutions that meet the evolving demands of the market and our customers.
            </p>
        </div>
        </div>
        
        <div className='flex  justify-center  items-center  '>
         <div className='w-[1100px] flex '>
            <div className='w-[450px] mt-10'>
                <img src={Line} alt='line'className=''/>
                <p className='text-start text-[20px] sm:text-[36px] leading-[43px] font-barlowcondensed  font-light italic text-gray-600 mt-10 mb-10'>
                Our commitment to innovation has led to the creation of a range of cutting-edge products designed to enhance the electric driving experience.
                <p>
                 Some of our notable contributions include:
                </p>
               
                </p>
                <img src={Line} alt='line'/>
            </div>
            <div className="w-[500px] m-10">
               
                <SmallCards/>
               
            
            </div>
        </div>
            
        </div>
        <div className='flex justify-center items-center'>
        <div className=' w-[1100px] my-20 flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base  font-normal'>
            <p>
            Our Electric Vehicle (EV) Development R&D team is a specialized group focused on researching, designing, and developing electric vehicles, 
            with a particular emphasis on improving their performance, efficiency, and sustainability. This team works on various aspects of EVs, 
            including the design of the vehicle itself, battery technology, electric drivetrains, charging infrastructure, and software systems that enhance the driving experience.
            </p>
        </div>
        </div>
        
        <div className='flex flex-row justify-center items-center'>
            <div className='w-[1100px] flex flex-row'>
            <div className='w-[600px] '>
             <p><Data/> </p>
            </div>
            <div className='w-1/2'>
            <img src={Whatsup} alt='whatsupImage' className='w-[356px] h-[475px] ml-10 '/>
            </div>
            </div>
            
        </div>
   <div>
      <div className='flex justify-center items-center'>
      <div className="  w-[920px] mt-20 text-start text-[20px] sm:text-[30px] leading-[36px] font-barlowcondensed  font-light italic text-gray-600">
      <p className='font-light  '>“</p>
      <p>The first auto assembly was designed & developed by early 2021, </p>
      <p> obtained first certificated for Powertrain (AIS-041) by March 31st, 2022.</p>
      <p>   From then, OHM Homologation process rapid its intensity & obtained</p>
      <p>  New Regulation battery (AIS-156) certification, ARAI certification for both</p>
      <p> L5 - Cargo & Passenger by Mid 2023.</p>
      <p  className='flex flex-col justify-end'>“ </p>
      </div>
      
      
      </div>
      
    </div>
        <div className='flex justify-center items-center'>
           <div className='w-[1100px] flex flex-row'>
           <div className='w-[900px]' >
            <p className='mt-10 font-barlowsemicondensed font-semibold text-[24px] mb-4'>Team Head</p>  
            <p className='  text-start text-[20px] sm:text-[40px] leading-[48px] font-barlowcondensed  font-light italic text-gray-600  mb-10'>
            Meet Mr. Karan Reddy: Innovator Behind OHM’s Visionary Journey
            </p>
            <p className=' flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base mb-6 font-normal'>
            Mr. Karan Reddy’s dedication, vision, and trust in his team set the foundation for OHM’s groundbreaking journey in revolutionizing the automotive sector.
             As the company continues to innovate and expand its horizons with his enthusiastic leadership, India stands a great chance at making its mark globally with extraordinary, “Made-in-India” products that reflect quality and resilience.
             With Mr. Reddy at the helm, the future of OHM looks exceedingly bright and filled with promise.
            </p>
            <p className=' flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base  font-normal'>
            Research and Development (R&D) is a critical phase in the process of innovation and advancement within businesses, organizations, and industries. 
            It involves systematic work undertaken to increase knowledge, develop new products, services, or technologies, and improve existing ones.
             The main objective of R&D is to create new solutions that can drive progress, efficiency, and profitability while meeting market demands or solving specific problems.
            </p>
            <p className='text-start text-[20px] sm:text-[40px] leading-[48px] font-barlowcondensed  font-light italic text-gray-600 mt-10 mb-10'>
            OHM R&D: Innovating Electric Vehicles Through Necessity
            </p>
            <p className=' flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base  font-normal mb-6'>
            At OHM R&D, our journey has just begun. We understand that the landscape of transportation is continuously changing, and we remain steadfast in our mission to lead the evolution of electric vehicles with products that prioritize sustainability, innovation, and customer satisfaction.
            </p>
            <p className=' flex flex-col justify-center align-center text-left font-barlowcondensed tracking-normal text-sm md:text-base  font-normal mb-6'>
            Through a commitment to relentless research and development, we are not only addressing the necessities of today but also pioneering the solutions for a better tomorrow in the realm of electric mobility. Join us as we drive forward into a greener future!
            </p>

            
            </div>
            <div className=' w-1/2 flex flex-col item-center justify-start mt-32 '>
            <img src={KarnaImg} alt='Nirmalsir' className="w-[100px] ml-20 " />
        <div className='ml-20'>
           <p>M Karunakar Reddy</p>
            <p>Founder & CEO</p>
            <div className='flex flex-row ml-4 gap-2'>
              <img src={LInkDin} alt='linkdin'/>
              <img src={Instagram} alt='instagram'/>
            </div>

           </div>
           
        </div>

            
            </div>
        </div>
    </div>
  )
}
export default ResearchContent ;